const downloadButtons = document.querySelectorAll('.download-image');

    downloadButtons.forEach(button => {
        button.addEventListener('click', e => {
            e.preventDefault();
            const id = button.dataset.id;
            const target = document.querySelector(`.download-${id}`);
            target.click();
        });
    });
