// modal.js

import {gsap} from "gsap";

class Modal {
    constructor() {
        this.modalContainer = document.querySelector('.modal-container');
        this.modalContent = this.modalContainer.querySelector('.modal-content');
        this.modalCloseIcon = document.querySelector('.close-modal-icon');
        this.masterTl = gsap.timeline({defaults: {duration: 0.05, ease: "power3.inOut"}});
        this.masterTl.add(this.animateShowModal());
        this.masterTl.add(this.animateCloseIcon());
        this.masterTl.pause();
        this.modalToggleTriggers = document.querySelectorAll('.toggle-modal');

        this.modalCloseIcon.addEventListener('mouseover', this.closeButtonHoverOn.bind(this));
        this.modalCloseIcon.addEventListener('mouseout', this.closeButtonHoverOff.bind(this));
        this.modalCloseIcon.addEventListener('click', this.toggleModal.bind(this));

        // Loop through toggleModalTriggers and add event listeners
        this.modalToggleTriggers.forEach(trigger => {
            trigger.addEventListener('click', this.toggleModal.bind(this));
        });
    }

    animateShowModal() {
        const tl = gsap.timeline();
        tl.to(this.modalContainer, {duration: 0.2, boxShadow: '0 0 0 9999px #00000090'})
            .fromTo(this.modalContainer, {bottom: '-100vh'}, {top: '0', duration: 0.3});
        return tl;
    }

    animateCloseIcon() {
        const tl = gsap.timeline();

        tl.fromTo(this.modalCloseIcon,
            {
                x: 60,
                opacity: 0,
                visibility: "hidden",
            },
            {
                opacity: 1,
                visibility: "visible",
                x: 0,
                duration: 0.05,
            });
        return tl;
    }

    toggleModal(e) {
        e.preventDefault();
        const isOpen = this.modalContainer.classList.toggle('open');

        if (isOpen) {
            if (e.target.tagName === 'A') {
                const injectedContentId = e.target.dataset.modalContentId;
                const injectedContent = document.getElementById(injectedContentId);
                this.modalContent.innerHTML = injectedContent.innerHTML;
            } else {
                // If the clicked element is not an anchor, search for the closest anchor in the parent hierarchy
                const closestAnchor = e.target.closest('a');
                if (closestAnchor) {
                    const injectedContentId = closestAnchor.dataset.modalContentId;
                    const injectedContent = document.getElementById(injectedContentId);
                    this.modalContent.innerHTML = injectedContent.innerHTML;
                } else {
                    // No anchor tag found in the hierarchy
                    this.modalContent.innerHTML = '';
                }
            }
            document.body.classList.add('modal-open');
        } else {
            this.modalContent.innerHTML = '';
            document.body.classList.remove('modal-open');
        }
        if (isOpen) {
            this.masterTl.play();
        } else {
            this.masterTl.reverse();
        }
    }

    closeButtonHoverOn() {
        if (!this.modalCloseIcon.classList.contains('hovered')) {
            this.modalCloseIcon.classList.add('hovered');
            gsap.to(this.modalCloseIcon.querySelector('.bar-1'), {x: -10, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.modalCloseIcon.querySelector('.bar-2'), {x: 10, duration: 0.2, ease: 'power3.inOut'});
        }
    }

    closeButtonHoverOff() {
        if (this.modalCloseIcon.classList.contains('hovered')) {
            this.modalCloseIcon.classList.remove('hovered');
            gsap.to(this.modalCloseIcon.querySelector('.bar-1'), {x: 0, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.modalCloseIcon.querySelector('.bar-2'), {x: 0, duration: 0.2, ease: 'power3.inOut'});
        }
    }
}

export default Modal;
