const iframes = document.querySelectorAll('.embed-container iframe');

iframes.forEach(iframe => {
    const width = iframe.getAttribute('width');
    const height = iframe.getAttribute('height');
    //console.log(width, height);

    if (!(width && height)) {
        return;
    }

    const aspectRatio = height / width;
    iframe.dataset.aspectRatio = aspectRatio;
    if (aspectRatio > 1) {
        iframe.classList.add('portrait');
        iframe.parentElement.classList.add('portrait');
        //iframe.parentElement.parentElement.classList.add('portrait');
    } else {
        iframe.classList.add('landscape');
        iframe.parentElement.classList.add('landscape');
        //iframe.parentElement.parentElement.classList.add('landscape');
    }
});
