const searchForm = document.querySelector('.site-header .search__form-wrapper');
const searchToggle = document.querySelector('.site-header .search__toggle');
searchToggle.addEventListener('click', (e) => {
    toggleSearch(e, searchForm);
});

function toggleSearch(e) {
    e.preventDefault();
    searchForm.classList.toggle('search-form--open');
    searchToggle.classList.toggle('search-form--open');
    searchForm.style.display = (searchForm.style.display === 'none' || searchForm.style.display === '') ? 'block' : 'none';
    searchForm.querySelector('input').focus();
}
