// Import the functions from animate.js
//import './damselfly-creative-console';
import {init as initAnimations} from './animate';
import './swiper';
import './sticky-header';
import './search-form';
import './back-button';
import './download-image';
import './iframe-orientation';
import Menu from './menu';
import Modal from './modal';
//import './taxonomy-lazy-load';

import '../scss/main.scss'


// Initialize animations when the DOM is ready
document.addEventListener('DOMContentLoaded', () => {
    initAnimations();
    const menu = new Menu();
    const modal = new Modal();
});

