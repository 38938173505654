// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import {gsap} from 'gsap';
import Player from '@vimeo/player';

// import styles bundle
import 'swiper/css/bundle';

// init Swiper:
const swiperFullWidth = new Swiper('.swiper.home, .swiper.header-slider', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    autoplay: {
        delay: 1000,
    },
    effect: 'fade',
});

// Single Model
const swiperModel = new Swiper('.swiper.single-model', {
    // Optional parameters
    direction: 'horizontal',
    //loop: true,
    autoplay: {
        delay: 4000,
    },
    effect: 'slide',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
        640: {
            slidesPerView: "auto",
        }
    }
});

// Single Creative
const swiperCreative = new Swiper('.swiper.single-creative', {
    // Optional parameters
    direction: 'horizontal',
    //loop: true,
    autoplay: {
        delay: 4000,
    },
    effect: 'slide',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
    spaceBetween: 10,
    initialSlide: 1,
    // Responsive breakpoints
    breakpoints: {
        640: {
            // slidesPerView: "auto",
        }
    },
});

// Set the primary slide when the slide changes
swiperCreative.on('slideNextTransitionStart', function (swiper) {
    const previousSlide = swiper.slides[swiper.previousIndex];
    const primarySlide = document.getElementById('primary-slide');
    primarySlide.innerHTML = previousSlide.innerHTML;
    gsap.fromTo(
        primarySlide,
        {
            x: 60,
            opacity: 0.1,
            visibility: "hidden",
        },
        {
            duration: 0.3,
            opacity: 1,
            visibility: "visible",
            x: 0,
            ease: "power3.inOut",
        }
    );
});

swiperCreative.on('slidePrevTransitionStart', function (swiper) {
    let previousSlide = swiper.slides[swiper.activeIndex - 1];
    if (swiper.isBeginning) {
        previousSlide = swiper.slides[swiper.slides.length - 1];
    }

    const primarySlide = document.getElementById('primary-slide');
    primarySlide.innerHTML = previousSlide.innerHTML;
    gsap.fromTo(
        primarySlide,
        {
            x: -60,
            opacity: 0.1,
            visibility: "hidden",
        },
        {
            duration: 0.3,
            opacity: 1,
            visibility: "visible",
            x: 0,
            ease: "power3.inOut",
        }
    );
});

// Image Scroll Page
const swiperImageScrollPage = new Swiper('.swiper.image-scroll', {
    direction: 'vertical',
    lazy: true,
    //loop: true,
    autoplay: {
        delay: 6000,
    },
    effect: 'slide',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
        640: {
            slidesPerView: "auto",
        }
    }
});

// Brands
const swiperBrands = new Swiper('.swiper.brands', {
    autoplay: {
        delay: 2000,
    },
    effect: 'slide',
    /*navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },*/
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 10,
    grid: {
        fill: 'row',
        rows: 1,
    },
    breakpoints: {
        640: {
            slidesPerView: 3,
            grid: {
                fill: 'row',
                rows: 2,
            },
        },
        1024: {
            slidesPerView: 3,
            grid: {
                fill: 'row',
                rows: 2,
            },
        }
    },
});

// Set the height of the slides to half of the width
swiperBrands.slides.forEach(slide => {
    slide.style.height = (slide.offsetWidth / 2.5) + 'px';
    slide.querySelector('img').style.height = (slide.offsetWidth / 2.5) + 'px';
});


export const buildModelArchiveSwiper = element => {
    // Set up the video player
    let videoPlayer = null;
    const videoIframe = element.querySelector('iframe');
    if (videoIframe) {
        //console.log(videoIframe, element.id);
        videoPlayer = new Player(videoIframe, {
            id: element.id,
        });
        videoPlayer.on('play', function () {
            //console.log('Played the video');
        });

        videoPlayer.getVideoTitle().then(function (title) {
            //console.log('title:', title);
        });

        // Pause the video initially
        videoPlayer.pause();
    }
    //console.log(element.id);
    const swiperInstance = new Swiper(`#${element.id}`, {
        direction: 'horizontal',
        loop: true,
        autoplay: {
            delay: 300,
            disableOnInteraction: false,
        },
        effect: 'fade',
        preventClicks: false,
        preventClicksPropagation: false,
    });

    // Pause the swiper initially
    //swiperInstance.autoplay.stop();

    // Get the swiper container element
    const swiperContainer = element;

    // Play the swiper on mouseover
    swiperContainer.addEventListener('mouseenter', function () {
        if (videoPlayer !== null) {
            videoPlayer.play();
        } else {
            swiperInstance.autoplay.start();
        }
    });

    // Pause the swiper on mouseout
    swiperContainer.addEventListener('mouseleave', function () {
        if (videoPlayer !== null) {
            videoPlayer.pause();
        } else {
            swiperInstance.autoplay.stop();
        }
    });

    return swiperInstance;
};

//const allSliders = document.querySelectorAll('.model-carousel');
//allSliders.forEach(slider => buildModelArchiveSwiper(slider));

const mainContent = document.getElementById('main');
mainContent.addEventListener('mousemove', function (event) {
    if (event.target.closest('.model-carousel:not(.swiper-initialized)')) {
        const modelCarousel = event.target.closest('.model-carousel');
        buildModelArchiveSwiper(modelCarousel);
    }
})