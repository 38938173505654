const ScreenWidth = (function () {
  const breakpoints = {
    xs: 0,      // Extra small devices
    sm: 576,    // Small devices
    md: 768,    // Medium devices
    lg: 992,    // Large devices
    xl: 1200    // Extra-large devices
  };

  function currentBreakpoint() {
    const screenWidth = window.screen.width;
    if (screenWidth < breakpoints.sm) {
      return 'xs';
    } else if (screenWidth < breakpoints.md) {
      return 'sm';
    } else if (screenWidth < breakpoints.lg) {
      return 'md';
    } else if (screenWidth < breakpoints.xl) {
      return 'lg';
    } else {
      return 'xl';
    }
  }

  function greaterThan(breakpoint) {
    return window.screen.width > breakpoints[breakpoint];
  }

  function lessThan(breakpoint) {
    return window.screen.width < breakpoints[breakpoint];
  }

  return {
    currentBreakpoint,
    greaterThan,
    lessThan
  };
})();

export default ScreenWidth;
