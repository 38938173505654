import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export function fadeInBatch(batch, stagger = 0.025, delay = 0) {
    ScrollTrigger.batch(batch, {
        markers: false,
        once: true,
        onEnter: (batch) => {
            gsap.fromTo(
                batch,
                {
                    y: (index, target) =>
                        target.getAttribute("data-direction") === "down" ? -60 : 60,
                    opacity: 0,
                    visibility: "hidden",
                },
                {
                    duration: 0.5,
                    opacity: 1,
                    visibility: "visible",
                    stagger: stagger,
                    y: 0,
                    ease: "power3.inOut",
                    delay: delay,
                }
            );
            batch.forEach(element => {
                element.classList.add('batch-animate-in-complete');
            })
        },
    });
}

export function zoomHover(batch, scale = 1.15) {
    batch.forEach((element) => {
        element.addEventListener("mouseenter", () => {
            gsap.to(element, {
                scale: (index, target) =>
                    parseFloat(target.getAttribute("data-scale")) || scale,
                duration: 0.2,
                ease: "power3.inOut",
            });
        });
        element.addEventListener("mouseleave", () => {
            gsap.to(element, {scale: 1, duration: 0.2, ease: "power3.inOut"});
        });
    });
}

export function fadeInHover(batch) {
    batch.forEach((element) => {
        element.addEventListener("mouseenter", () => {
            gsap.to(
                element, {
                    duration: 0.2,
                    opacity: 1,
                    visibility: "visible",
                    y: 0,
                    ease: "power3.inOut"
                }
            );
        });
        element.addEventListener("mouseleave", () => {
            gsap.to(element, {
                duration: 0.2,
                opacity: 1,
                visibility: "hidden",
                y: -60,
                ease: "power3.inOut"
            });
        });
    });
}


export function fadeInPopUp(popUpWrapper) {
    // Pink Pop Up
    const popUpClose = popUpWrapper.querySelector('.pop-up-close');
    popUpWrapper.style.opacity = 0;
    popUpWrapper.style.display = 'none';

    popUpClose.addEventListener('click', () => {
        gsap.to(popUpWrapper, {
            opacity: 0,
            duration: 0.4,
            display: 'none',
            scale: 1.3,
            ease: "elastic.inOut"
        });
    });

    gsap.to(popUpWrapper, {
        opacity: 1,
        duration: 0.4,
        delay: 0.8,
        display: 'flex',
        ease: "power3.inOut"
    });
}

export function initBatches() {
    const animatedBatches = document.querySelectorAll(".animated-batch");
    if (animatedBatches.length !== 0) {
        animatedBatches.forEach(function (batch) {
            const stagger = parseFloat(batch.getAttribute("data-stagger")) || 0.025;
            const animation = batch.getAttribute("data-animation") || "fade-in";
            const delay = parseFloat(batch.getAttribute("data-delay")) || 0;
            const items = batch.querySelectorAll(".animated-item:not(.batch-animate-in-complete)");
            if (animation === "fade-in") {
                fadeInBatch(items, stagger, delay);
            }
        });
    }
}

export function init() {
    const fadeIns = document.querySelectorAll(".animate.fade-in");
    if (fadeIns.length > 0) {
        fadeInBatch(fadeIns);
    }

    initBatches();

    const fadeInsDelay = document.querySelectorAll(".animate.fade-in-delay");
    if (fadeInsDelay.length > 0) {
        gsap.fromTo(
            fadeInsDelay,
            {
                y: 60,
                opacity: 0,
                visibility: "hidden",
            },
            {
                duration: 0.2,
                opacity: 1,
                visibility: "visible",
                y: 0,
                ease: "power3.inOut",
                delay: 0.2,
            }
        );
    }

    const fadeInsLeft = document.querySelectorAll(".animate.fade-in-left");
    if (fadeInsLeft.length > 0) {
        gsap.fromTo(
            fadeInsLeft,
            {
                x: 120,
                opacity: 0,
                visibility: "hidden",
            },
            {
                duration: 0.2,
                opacity: 1,
                visibility: "visible",
                x: 0,
                ease: "power3.inOut",
                delay: 0.2,
            }
        );
    }

    const fadeInsRight = document.querySelectorAll(".animate.fade-in-right");
    if (fadeInsRight.length > 0) {
        gsap.fromTo(
            fadeInsRight,
            {
                x: -120,
                opacity: 0,
                visibility: "hidden",
            },
            {
                duration: 0.2,
                opacity: 1,
                visibility: "visible",
                x: 0,
                ease: "power3.inOut",
            }
        );
    }

    const popUpWrapper = document.querySelector('.pop-up-wrapper');
    if (popUpWrapper) {
        fadeInPopUp(popUpWrapper);
    }

    const zoomHovers = document.querySelectorAll(".animate.zoom-hover");

    if (zoomHovers.length > 0) {
        zoomHover(zoomHovers);
    }
    /*
        const fadeInHovers = document.querySelectorAll(".animate.fade-in-hover");
        if (fadeInHovers.length > 0) {
            fadeInHover(fadeInHovers);
        }*/
}

document.addEventListener("lazyloaded", function (e) {
    console.log("lazyloaded fired");
    ScrollTrigger.refresh();
});
