// menu.js

import {gsap} from "gsap";
import ScreenWidth from "./screenWidth";

class Menu {
    constructor() {
        this.hamburgerMenu = document.querySelector('.hamburger-menu');
        this.menuContent = document.querySelector('.menu-content');
        this.closeIcon = document.querySelector('.close-icon');
        this.mainMenu = document.querySelector('#main-menu');
        this.primaryMenu = document.querySelector('#primary-menu');
        this.primaryTopLevelLi = Array.from(this.primaryMenu.querySelectorAll('li')).filter(li => li.parentElement === this.primaryMenu);
        this.secondaryMenu = document.querySelector('#secondary-menu');
        this.menuItems = this.menuContent.querySelectorAll('ul > li');
        this.topLevelMenuItems = Array.from(this.menuItems).filter(li => li.parentElement === this.menuContent.querySelector('ul'));
        this.subMenus = this.menuContent.querySelectorAll('.sub-menu');
        this.imageCanvas = document.querySelector('#image-canvas');

        this.masterTl = gsap.timeline({defaults: {duration: 0.05, ease: "power3.inOut"}});
        this.masterTl.add(this.animateShowMenu());
        this.masterTl.add(this.animatePrimary(this.topLevelMenuItems));
        this.masterTl.add(this.animateSecondary());
        this.masterTl.add(this.animateCloseIcon());
        this.masterTl.pause();

        this.setupEventListeners();
    }

    animateShowMenu() {
        const tl = gsap.timeline();
        tl.to(this.menuContent, {duration: 0.2, boxShadow: '0 0 0 9999px #00000090'})
            .to(this.menuContent, {right: '-0.00001%', duration: 0.2});
        return tl;
    }

    animateCloseIcon() {
        const tl = gsap.timeline();
        tl.fromTo(this.closeIcon, {x: 60, opacity: 0, visibility: "hidden"}, {
            opacity: 1,
            visibility: "visible",
            x: 0,
            duration: 0.05,
        });
        return tl;
    }

    animatePrimary(menuItems) {
        const tl = gsap.timeline();
        tl.fromTo(menuItems, {x: 60, opacity: 0, visibility: "hidden"}, {
            opacity: 1,
            visibility: "visible",
            stagger: 0.025,
            x: 0,
        });
        return tl;
    }

    animateSecondary() {
        const tl = gsap.timeline();
        tl.fromTo(this.secondaryMenu.querySelectorAll('li'), {y: 60, opacity: 0, visibility: "hidden"}, {
            opacity: 1,
            visibility: "visible",
            stagger: 0.025,
            y: 0,
        });
        return tl;
    }

    hamburgerHoverOn() {
        if (!this.hamburgerMenu.classList.contains('hovered')) {
            this.hamburgerMenu.classList.add('hovered');
            gsap.to(this.hamburgerMenu.querySelector('.bar-1'), {rotate: 45, y: 12, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.hamburgerMenu.querySelector('.bar-2'), {opacity: 0, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.hamburgerMenu.querySelector('.bar-3'), {rotate: -45, y: -12, duration: 0.2, ease: 'power3.inOut'});
        }
    }

    hamburgerHoverOff() {
        if (this.hamburgerMenu.classList.contains('hovered')) {
            this.hamburgerMenu.classList.remove('hovered');
            gsap.to(this.hamburgerMenu.querySelector('.bar-1'), {rotate: 0, y: 0, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.hamburgerMenu.querySelector('.bar-2'), {opacity: 1, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.hamburgerMenu.querySelector('.bar-3'), {rotate: 0, y: 0, duration: 0.2, ease: 'power3.inOut'});
        }
    }

    toggleMenu() {
        const isOpen = this.hamburgerMenu.classList.toggle('open');
        if (isOpen) {
            this.masterTl.play();
        } else {
            this.masterTl.reverse();
        }
    }

    closeButtonHoverOn() {
        if (!this.closeIcon.classList.contains('hovered')) {
            this.closeIcon.classList.add('hovered');
            gsap.to(this.closeIcon.querySelector('.bar-1'), {y: -10, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.closeIcon.querySelector('.bar-2'), {y: 10, duration: 0.2, ease: 'power3.inOut'});
        }
    }

    closeButtonHoverOff() {
        if (this.closeIcon.classList.contains('hovered')) {
            this.closeIcon.classList.remove('hovered');
            gsap.to(this.closeIcon.querySelector('.bar-1'), {y: 0, duration: 0.2, ease: 'power3.inOut'});
            gsap.to(this.closeIcon.querySelector('.bar-2'), {y: 0, duration: 0.2, ease: 'power3.inOut'});
        }
    }

    menuItemHoverOn(event) {
        const hoveredLink = event.target;
        const hoveredMenuItem = hoveredLink.parentElement;

        /*
        RESET state of other primary menu items
        If the hovered link is
         - NOT a top level link AND it is not active,
         - THEN remove all active classes and hide all sub menus
         */
        if (hoveredLink.tagName === 'A' && !hoveredMenuItem.classList.contains('active')) {
            if (this.primaryTopLevelLi.includes(hoveredMenuItem)) {
                this.removeActiveClasses();
                this.hideSubMenus();
            }
        }

        /*
        SET the state of all menu items (primary & secondary)
         */
        this.menuItems.forEach((item) => {
            const link = item.querySelector('a');

            if (this.isSecondaryMenuItem(item) && this.isSecondaryMenuItem(hoveredMenuItem)) {
                if (link !== hoveredLink) {
                    this.unsetHoverState(item);
                } else {
                    this.setHoverState(hoveredMenuItem);
                }
            } else {
                // If a child of the menu item is hovered, do nothing
                // Hide Sub Menu if another top level menu item is hovered
                if (link !== hoveredLink) {
                    const isChildHovered = [...item.querySelectorAll(':hover')].some(el => item.contains(el));
                    if (!isChildHovered) {
                        this.unsetHoverState(item);
                    }
                } else {
                    // Set the hover state for the hovered link and show the image
                    this.setHoverState(hoveredMenuItem);
                    this.showMenuItemImage(hoveredMenuItem);
                }
            }
        });
    }

    menuItemHoverOff() {
        // Are any primary menu list items active?
        const haveActiveTopLevelLink = this.primaryTopLevelLi.some(item => item.classList.contains('active'))

        this.menuItems.forEach((item) => {
            // If no top level menu items are active
            // AND the item is a top level menu item,
            // unset the hover state
            if (!haveActiveTopLevelLink && this.primaryTopLevelLi.includes(item)) {
                this.resetHoverState(item);
                this.imageCanvas.innerHTML = '';
            }

            // It's not the primary top level menu so reset the hover state
            if (!this.primaryTopLevelLi.includes(item)) {
                this.resetHoverState(item);
                this.imageCanvas.innerHTML = '';
            }
        });
    }

    primaryTopLevelClick(event) {
        const link = event.target;
        const menuItem = link.parentElement;
        const isLastItem = menuItem === menuItem.parentElement.lastElementChild;

        if (this.primaryTopLevelLi.includes(menuItem)) {

            if (this.hasSubMenu(menuItem)) {
                event.preventDefault();
                menuItem.classList.add('active');
            }

            this.hideSubMenus();

            const subMenu = menuItem.querySelector('.sub-menu');
            if (!this.isElementVisible(subMenu)) {
                const mainMenuRect = this.mainMenu.getBoundingClientRect();
                const primaryMenuRect = this.primaryMenu.getBoundingClientRect();
                const subMenuRect = subMenu.getBoundingClientRect();
                const adjustmentPx = ScreenWidth.greaterThan('md') ? 20 : 10;
                const right = mainMenuRect.right - primaryMenuRect.right - adjustmentPx;
                subMenu.style.right = `-${right}px`;
                if (isLastItem) {
                    subMenu.style.bottom = '0';
                }
                gsap.to(subMenu, {opacity: 1, visibility: "visible", display: 'flex', duration: 0.2, ease: 'power3.inOut'});

            }
        }
    }

    setHoverState(item) {
        const link = item.querySelector('a');
        const underline = item.querySelector('.underline')

        gsap.to(link, {opacity: 1, duration: 0.2, ease: 'power3.inOut'});

        if (underline !== null) {
            gsap.to(underline, {width: '100%', duration: 0.4, ease: 'power3.inOut'});
        }
    }

    unsetHoverState(item) {
        const link = item.querySelector('a');
        const underline = item.querySelector('.underline')

        // Don't grey out if sub menu item on mobile
        if (ScreenWidth.greaterThan('md') || !this.isSubMenuItem(item)) {
            // Grey out
            gsap.to(link, {opacity: 0.2, duration: 0.2, ease: 'power3.inOut'});
        }

        // Hide underline
        if (underline !== null) {
            gsap.to(underline, {width: 0, duration: 0.4, ease: 'power3.inOut'});
        }
    }

    resetHoverState(item) {
        const link = item.querySelector('a');
        const underline = item.querySelector('.underline')

        // Return to black
        gsap.to(link, {opacity: 1, duration: 0.2, ease: 'power3.inOut'});

        // Hide underline
        if (underline !== null) {
            gsap.to(underline, {width: 0, duration: 0.4, ease: 'power3.inOut'});
        }
    }

    hideSubMenus() {
        this.subMenus.forEach((subMenu) => {
            gsap.to(subMenu, {opacity: 0, visibility: "hidden", display: 'none', duration: 0.2, ease: 'power3.inOut'});
        });
    }

    showMenuItemImage(menuItem) {
        const image = menuItem.querySelector('.image');
        if (image !== null) {
            const clonedImageContainer = image.cloneNode(true);
            const clonedImage = clonedImageContainer.querySelector('img');

            this.imageCanvas.innerHTML = '';
            this.imageCanvas.appendChild(clonedImageContainer);

            const canvasWidth = this.imageCanvas.offsetWidth;
            const canvasHeight = this.imageCanvas.offsetHeight;

            const originalWidth = clonedImage.width;
            const originalHeight = clonedImage.height;
            const aspectRatio = originalWidth / originalHeight;

            let newWidth = canvasWidth * 0.75;
            let newHeight = newWidth / aspectRatio;

            if (newHeight > canvasHeight) {
                newHeight = canvasHeight * 0.75;
                newWidth = newHeight * aspectRatio;
            }

            clonedImageContainer.style.width = `${newWidth}px`;
            clonedImageContainer.style.height = `${newHeight}px`;

            const maxX = canvasWidth - newWidth;
            const maxY = canvasHeight - newHeight;

            const randomX = Math.random() * maxX;
            const randomY = Math.random() * maxY;

            const maxDisplacement = 50;
            const deltaX = Math.random() * maxDisplacement * 2 - maxDisplacement;
            const deltaY = Math.random() * maxDisplacement * 2 - maxDisplacement;

            const startX = Math.min(randomX + deltaX, maxX);
            const startY = Math.min(randomY + deltaY, maxY);

            gsap.set(clonedImageContainer, {
                x: startX,
                y: startY,
                opacity: 0,
            });

            gsap.to(clonedImageContainer, {
                x: randomX,
                y: randomY,
                opacity: 1,
                duration: 0.5,
                ease: 'power1.out',
            });
        }
    }

    hasSubMenu(menuItem) {
        return menuItem.classList.contains('menu-item-has-children');
    }

    removeActiveClasses() {
        this.primaryTopLevelLi.forEach((item) => {
            item.classList.remove('active');
        });
    }

    isElementVisible(element) {
        return element.offsetParent !== null;
    }

    isSubMenuItem(element) {
        return element.parentElement.classList.contains('sub-menu');
    }

    isSecondaryMenuItem(element) {
        return element.parentElement === this.secondaryMenu;
    }

    setupEventListeners() {
        this.hamburgerMenu.addEventListener('mouseover', this.hamburgerHoverOn.bind(this));
        this.hamburgerMenu.addEventListener('mouseout', this.hamburgerHoverOff.bind(this));
        this.hamburgerMenu.addEventListener('click', this.toggleMenu.bind(this));

        this.closeIcon.addEventListener('mouseover', this.closeButtonHoverOn.bind(this));
        this.closeIcon.addEventListener('mouseout', this.closeButtonHoverOff.bind(this));
        this.closeIcon.addEventListener('click', this.toggleMenu.bind(this));

        this.primaryMenu.addEventListener('mouseover', this.menuItemHoverOn.bind(this));
        this.primaryMenu.addEventListener('mouseout', this.menuItemHoverOff.bind(this));

        this.secondaryMenu.addEventListener('mouseover', this.menuItemHoverOn.bind(this));
        this.secondaryMenu.addEventListener('mouseout', this.menuItemHoverOff.bind(this));

        this.primaryMenu.addEventListener('click', this.primaryTopLevelClick.bind(this));
    }
}

export default Menu;
