const body = document.querySelector('body');

if (body.classList.contains('page-template-video-header')) {
    const headerH1Wrappper = document.querySelector('header.page-header');
    body.classList.add('fixed');
    window.addEventListener('scroll', function () {
        // Get the current scroll position
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;

        // Check if the scroll position is greater than or equal to 100vh
        if (scrollPosition >= window.innerHeight) {
            headerH1Wrappper.classList.add('hide');
            headerH1Wrappper.classList.remove('show');
            body.classList.remove('fixed')
            body.classList.add('sticky');
        } else {
            headerH1Wrappper.classList.add('show');
            headerH1Wrappper.classList.remove('hide');
            body.classList.add('fixed')
            body.classList.remove('sticky')
        }
    });
}
